// Email form
const contactForm = document.getElementById("contact-form");
const formMessage = document.querySelector(".form-message");

contactForm.addEventListener("submit", async (event) => {
  event.preventDefault();

  formMessage.textContent = 'Kérelem küldése...';

  const formElements = event.target.elements;
  const firstName = formElements.firstName.value;
  const lastName = formElements.lastName.value;
  const email = formElements.email.value;
  const message = formElements.message.value;

  let captcha;
  try{
    captcha = await grecaptcha.execute('6Le9_V0aAAAAAFoStYPfm3q25EYuBKx4LE93E9Gt', { action: 'homepage'});
  } catch(error){
    console.log(error);
  }

  let backendResult;
  let isSuccess;

  return 10;
  try{
    backendResult = await fetch(`${window.location.origin}:3000/email`, {
      method: 'POST',
      headers:{
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({firstName, lastName, email, message, captcha})
    });

    backendResult = await backendResult.json();
    backendResult = backendResult.message;
    isSuccess = true;
    
  } catch(error){
    console.log(error);
    isSuccess = false;
    backendResult = error.message;
  }

  formMessage.textContent = backendResult;

  if(isSuccess){
    formMessage.classList.add('is-success');
  } else {
    formMessage.classList.add('is-failed');
  }

  setTimeout(() => {
    formMessage.textContent = '';
    formMessage.classList.remove('is-success');
    formMessage.classList.remove('is-failed');
  }, 1000);

  return false;
});
