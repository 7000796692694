const homeContainer = document.querySelector(".home-container");
const servicesContainer = document.querySelector(".services-container");
const aboutContainer = document.querySelector(".about-container");
const contactContainer = document.querySelector(".contact-container");

const containers = {
  home: homeContainer,
  services: servicesContainer,
  about: aboutContainer,
  contact: contactContainer,
};

const menuItems = [...document.querySelectorAll(".menu-item span")];
let windowWidth = window.innerWidth;

window.addEventListener("resize", (event) => {
  windowWidth = window.innerWidth;
});

export const onScroll = () => {
  const scrollNavElement = document.getElementById("scroll-nav-container");
  const className = scrollNavElement.className;

  if (windowWidth >= 1025) {
    if (window.scrollY - scrollNavElement.offsetTop >= 140) {
      if (className !== "navigation-scroll") {
        scrollNavElement.className = "navigation-scroll";
      }
    } else {
      if (className === "navigation-scroll") {
        scrollNavElement.className = "navigation-hidden";
      }
    }
  }
};

const menuOnClick = (event) => {
  const targetName = event.target.getAttribute("data-target");

  [...document.querySelectorAll(`.menu-item-active`)].forEach((element) => {
    element.classList.remove("menu-item-active");
  });

  [
    ...document.querySelectorAll(
      `.menu-item span[data-target="${targetName}"]`
    ),
  ].forEach((element) => {
    element.parentElement.classList.add("menu-item-active");
  });

  containers[targetName].scrollIntoView();
};

menuItems.forEach((menuItem) =>
  menuItem.addEventListener("click", menuOnClick)
);
