const navigation = document.getElementById("nav-hamburger");

const navigationButton = document.querySelector(".hamburger-btn");
const navigationItems = [...document.querySelectorAll(".hamburger-menu-item")];
const homeContainer = document.querySelector(".home-container");
const servicesContainer = document.querySelector(".services-container");
const aboutContainer = document.querySelector(".about-container");
const contactContainer = document.querySelector(".contact-container");

const containers = {
  home: homeContainer,
  services: servicesContainer,
  about: aboutContainer,
  contact: contactContainer,
};

navigationButton.addEventListener("click", (event) => {
  navigation.classList.toggle("hamburger-open");
  navigation.classList.toggle("hamburger-close");
  document.body.classList.toggle("disable-scroll");
});

navigationItems.forEach((navItem) => {
  navItem.addEventListener("click", (event) => {
    const node =
      event.target.nodeName === "LI" ? event.target.children[0] : event.target;
    const menuContainer =
      event.target.nodeName === "LI"
        ? event.target
        : event.target.parentElement;
    const targetName = node.getAttribute("data-target");

    [...document.querySelectorAll(`.hamburger-menu-item--active`)].forEach(
      (element) => {
        element.classList.remove("hamburger-menu-item--active");
      }
    );

    menuContainer.classList.add("hamburger-menu-item--active");

    navigation.classList.toggle("hamburger-open");
    navigation.classList.toggle("hamburger-close");
    document.body.classList.toggle("disable-scroll");

    containers[targetName].scrollIntoView();
  });
});
