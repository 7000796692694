import { onScroll } from "./scroll";

window.addEventListener("load", (e) => {
  const loading = document.querySelector(".loading");

  document.body.classList.remove("disable-scroll");

  loading.style.opacity = 0;
  setTimeout(() => {
    document.body.removeChild(loading);
    window.addEventListener("scroll", onScroll);
  }, 500);
});
