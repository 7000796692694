const serviceOpenBtnList = [...document.querySelectorAll(".service-open-btn")].filter( x => x.getAttribute('data-service-type') !== 'maintenance');
const maintanance = document.querySelector('.service-open-btn[data-service-type="maintenance"]');
const closeSelectedBtn = document.querySelector(".close-selected");
const selectedHeading = document.querySelector(".selected-heading");
//const selectedServiceList = document.querySelector(".selected-price-list");
const selectedContainer = document.getElementById("selected-container");
const selectedListHeader = document.querySelector('.selected-list-header');
const selectedBody = document.querySelector('.selected-body');

const fetchServicesData = async () => {
  let servicesData;
  try {
    servicesData = await fetch("/service-prices.json").then((result) =>
      result.json()
    );
  } catch (error) {
    return null;
  }

  return servicesData;
};

const createItems = (servicesData, serviceType) => {
  return servicesData[serviceType].map((row, index, _array) => {
    const itemContainer = document.createElement("div");
    itemContainer.className = "selected-item-container";

    const questionInfo = document.createElement("span");
    questionInfo.className =
      "selected-question-info selected-question-info--closed";
    questionInfo.textContent = row.description;

    const questionButton = document.createElement("button");
    questionButton.className = "button-selected-question";
    questionButton.addEventListener("click", (event) => {
      questionButton.classList.toggle("button-selected-question--opened");
      questionInfo.classList.toggle("selected-question-info--closed");
      questionInfo.classList.toggle("selected-question-info--opened");
    });

    if (!row.description || row.description === "") {
      questionButton.disabled = true;
      questionButton.className += " selected-question-info--disabled";
    }

    const li = document.createElement("li");
    li.className = "selected-item";
    if (_array.length - 1 === index) {
      li.className += " selected-item-rounding";
    }

    const name = document.createElement("span");
    name.className = "selected-item-name";
    name.textContent = row.name;

    const row1 = document.createElement("div");
    row1.className = "selected-list-item-container";
    row1.append(name, questionButton);

    itemContainer.append(row1, questionInfo);

    const price = document.createElement("span");
    price.className = "selected-item-price";
    price.textContent = row.price + "ft";

    li.append(...[itemContainer, price]);
    return li;
  });
};

const clearSelected = () => {
  document.body.style.overflow = "unset";
  selectedContainer.classList.toggle("selected-closed");
  selectedContainer.classList.toggle("selected-opened");

  // Clear esc button listener
  document.removeEventListener("keydown", escButtonCloseSelected);

  setTimeout(() => {
    if(document.querySelector('.selected-price-list')){
      [...document.querySelector('.selected-price-list').children].forEach((element) => element.remove());
    }
  }, 250);
};

const escButtonCloseSelected = (event) => {
  if (event.key === "Escape") {
    clearSelected();
  }
};

selectedContainer.addEventListener("click", (event) => {
  if (selectedContainer.isEqualNode(event.target)) {
    clearSelected();
  }
});

serviceOpenBtnList.forEach((btn) => {
  btn.addEventListener("click", async (event) => {
    if(document.querySelector('.feature-description')){
      document.querySelector('.feature-description').remove();
    }
    
    selectedBody.innerHTML=`
      <div class="selected-list-header">
      <h2 class="selected-list-primary">Megnevezés</h2>
      <h3 class="selected-list-secondary">Árak</h3>
    </div>
    <ul class="selected-price-list"></ul>
  `;
    selectedListHeader.style.display = 'flex'; 

    const serviceType = event.target.getAttribute("data-service-type");
    const serviceTitle = event.target.previousElementSibling.textContent;

    event.target.classList.add("button-loading");
    const servicesData = await fetchServicesData();

    if (servicesData) {
      selectedHeading.textContent = serviceTitle;
  
      document.querySelector('.selected-price-list').append(...createItems(servicesData, serviceType));

      document.body.style.overflow = "hidden";

      selectedContainer.classList.toggle("selected-closed");
      selectedContainer.classList.toggle("selected-opened");

      // Handle esc button
      document.addEventListener("keydown", escButtonCloseSelected);

      setTimeout(() => {
        event.target.classList.remove("button-loading");
      }, 250);
    } else {
      event.target.classList.remove("button-loading");
    }
  });
});

closeSelectedBtn.addEventListener("click", clearSelected);

maintanance.addEventListener('click', e => {
  selectedContainer.classList.toggle("selected-closed");
  selectedContainer.classList.toggle("selected-opened");

  selectedHeading.textContent = 'Üzemeltetés szervíz';

  selectedListHeader.style.display = 'none';  
  selectedBody.innerHTML = `
  <div class="feature-description">
  <h1>Informatikai szaktanácsadás</h1>
<p>Kérdése lenne de nem talál megfelelő szakmai háttérrel rendelező embert? Forduljon hozzám, egyeztessünk és tegyünk pontot a dolog végére.</p>

<h1>IT üzemeltetési szolgáltatások</h1>

<ul>
    <li>WiFi felmérés, tervezés, vezeték nélküli hálózat kiépítése konfigurációja és optimalizálása</li>
    <li>Vezetékes hálózat kiépítése (UTP/LAN), kábelezés, kábelkihúzás és fali csatornákban elvezetés</li>
    <li>Fizikai hálózat tervezése, eszközök konfigurációja</li>
    <li>Hálózati eszközök (Router, Switch, Tűzfal, Vezeték nélküli access point) üzembe helyezése és beállítása</li>
    <li>Szerverszoba kialakításának tervezése kivitelezése.</li>
    <li>Áramfogyasztási kapacitás tervezése.</li>
    <li>Passzív hálózati eszközök és csatlakozók szerelése és cseréje.</li>
</ul>

<h1>IT rendszergazdai szolgáltatások</h1>
<ul>
    <li>Hálózati dokumentáció készítése és modernizációs javaslatok elkészítése.</li>
    <li>Távoli hibaelhárítás és távsegítség nyújtása </li>
    <li>Laptopok és asztali számítógépek karbantartása, tisztítása.</li>
    <li>Operációs rendszer és egyéb szoftverek telepítése, frissítése és személyre szabása</li>
    <li>Új számítástechnikai eszközök igény szerinti beszerzése, üzembe helyezése</li>
    <li>Windows Active Directory rendszer karbantartása rendszerkialakítása</li>
    <li>Centralizált rendszerkomponensek telepítése</li>
    <li>Monitorozó rendszerszer implementációja</li>
    <li>Virtualizált rendszerek kialakítása migrációja</li>
    <li>Cloud migrációk</li>
</ul>
</div>`;

  document.addEventListener("keydown", escButtonCloseSelected);
});